// Bootstrap brand color customization

/*     brand Colors              */

$brand-primary: #31304d !default;
$brand-info: #b6bbc4 !default;
$brand-success: $green-500 !default;
$brand-warning: $orange-500 !default;
$brand-danger: $red-500 !default;
$brand-rose: $pink-500 !default;
$brand-inverse: $black-color !default;
