@use "@angular/material" as mat;

@include mat.core();

// Set the default typography
@include mat.all-component-typographies(
  mat.define-typography-config($font-family: "Times")
);

// Define the default theme
$default-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$blue-palette, A700),
      accent: mat.define-palette(mat.$blue-palette, A400),
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Poppins",
      ),
    density: 0,
  )
);
::ng-deep .mat-select {
  background-color: red;
}

:host::ng-deep.mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mat-mdc-form-field-infix {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #2f80ed;
}

@include mat.all-component-themes($default-theme);
.primary {
  color: #31304d !important;
}

.secondary {
  color: #b6bbc4 !important;
}
.info {
  color: #b6bbc4 !important;
}
.modal-backdrop {
  z-index: 1 !important;
}
th,
td {
  border: 1px solid #ddd;
  // / padding: 8px; /
  text-align: center;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
div:where(.swal2-container) div:where(.swal2-popup) {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: small !important;
}
:host ::ng-deep.fc-datagrid-body .fc-scrollgrid-sync-table {
  width: 50px !important;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
