@mixin sidebar-background-color($background-color, $font-color) {
  .nav {
    .nav-item {
      .nav-link {
        color: black;
      }
      i {
        color: black;
      }

      &.active,
      &:hover {
        [data-toggle="collapse"] {
          color: black;
          i {
            color: black;
          }
        }
      }
    }
  }
  .user {
    a {
      color: $font-color;
    }
  }
  .simple-text {
    color: $font-color;
  }
  .sidebar-background:after {
    background: $background-color;
    opacity: 0.8;
  }
}

@mixin sidebar-active-color($font-color) {
  .nav {
    .nav-item {
      &.active > a:not([data-toggle="collapse"]) {
        color: $font-color;
        opacity: 1;
        background-color: #31304d;
        // @include shadow-big-color($font-color);

        i {
          color: white;
        }
      }
    }
  }
}

@mixin set-background-color-button($color) {
  li.active > a {
    background-color: $color;
    @include shadow-big-color($color);
  }
}
